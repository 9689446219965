import React, { useRef, useEffect, useLayoutEffect } from 'react'
import { getUserToken, getUser } from '@/utils/localData'
import { Navigate } from 'react-router-dom'
import { INVITED } from '@/utils/constant'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'
import {
  blockTop,
  blockBottom,
  logoLoading,
  textLogo,
  imgGif,
  ltTop,
  ltBottom,
  ltTopSP,
  ltBottomSP,
  imgBgWhatIs,
  imgMacInfolio,
  imgBgFeature,
  imgBgValue,
  imgBgNews,
  imgBgContact,
  bgBannerTop,
  iconScrollBl,
  imgWhatIsMac,
  iconScroll,
  feature1,
  feature2,
  feature3,
  feature4,
  iconTxt1,
  iconTxt2,
  iconTxt3,
  iconTxt4,
  imgDetailVl1,
  imgDetailVl2,
  news1,
  iconNtx,
} from '@/utils/images'
import { Navbar, Footer } from '@/components'
import useScript from '@/hooks/useScript'
import { scrollTop } from '@/utils/helper'
import ContactUs from './ContactUs'
import './styles.css'
import './wow.css'

const index = () => {
  const userToken = getUserToken()
  const user = getUser()
  const bodyWraperRef = useRef()
  // if (userToken && user.status != INVITED) return <Navigate to='/top' />

  useScript('dist/js/main.js')

  useLayoutEffect(() => {
    return () => {
      bodyWraperRef.current.className = ''
      scrollTop()
    }
  }, [])

  return (
    <Box ref={bodyWraperRef} className={`body-wrapper`}>
      <Box className={`loading--all-page`} id='loading--page'>
        <Box className={`block-top`}>
          <img className={`img-fluid w-100 transition-08s m-pc575`} src={blockTop} />
          <img className={`img-fluid w-100 transition-08s m-sp575`} src={ltTopSP} />
        </Box>
        <Box className={`loading--logo`}>
          <Box className={`block--logo`}>
            <img className={`img-fluid logo-loading transition-06s`} src={logoLoading} />
            <img className={`img-fluid text--logo transition-06s`} src={textLogo} />
            <Box className={`loading--item transition-1s`}>
              <img className={`img-fluid img--gif transition-1s`} src={imgGif} />
            </Box>
          </Box>
        </Box>
        <Box className={`bg--ld-top`}>
          <img className={`img-fluid`} src={ltTop} />
        </Box>
        <Box className={`bg--ld-bottom`}>
          <img className={`img-fluid`} src={ltBottom} />
        </Box>
        <Box className={`block-bottom `}>
          <img className={`w-100 img-fluid transition-08s m-pc575`} src={blockBottom} />
          <img className={`w-100 img-fluid transition-08s m-sp575`} src={ltBottomSP} />
        </Box>
      </Box>
      <Navbar />
      <Box className={`bg--all-page`}>
        <Box className={`image--banner-what banner--top transition--2s-slow`}>
          <Box className={`image--bg`}>
            <img className={`img-fluid w-100`} src={imgBgWhatIs} alt='Information Portfolio' />
          </Box>
          <Box className={`image--mac transition-1s`}>
            <img src={imgMacInfolio} alt='Information Portfolio'/>
          </Box>
        </Box>
        <Box className={`image--banner-feature transition-06s`}>
          <img className={`img-fluid w-100 transition-08s`} src={imgBgFeature} alt='Feature' />
        </Box>
        <Box className={`image--banner-value transition-06cubic`}>
          <img className={`img-fluid w-100 transition-06cubic`} src={imgBgValue} alt='Value' />
        </Box>
        <Box className={`image--banner-news transition-06cubic`}>
          <img className={`img-fluid w-100 transition-06cubic`} src={imgBgNews} alt='News' />
        </Box>
        <Box className={`image--banner-contact transition-06cubic`}>
          <img className={`img-fluid w-100 transition-06cubic bg-news-contact`} src={imgBgNews} alt='News' />
          <img className={`img-fluid w-100 transition-06cubic bg-contact`} src={imgBgContact} alt='Contact' />
        </Box>
      </Box>
      {/* Page */}
      <Box className={`lp--banner lp--all-page section--Page lp--whatIs`}>
        <Box className={`h2-title--head h2--whatIs text-center transition-06s`}>
          What is InFolio?
        </Box>
        <Box className={`h2-title--head h2--feature transition-06s`}>Features</Box>
        <Box className={`top__scroll-slider scrollSync slider__scrollbar transition--1s-slow`}>
          <Box className={`scroll__item`}></Box>
        </Box>
        <Box className={`title--head-value title--head-option2 transition--1s-slow`}>
          <Box className={`h2--value`}>Values</Box>
          <Box className={`text--txt`}>
            定常的な情報収集や<span>ベンチマーク調査</span>
            にかかる時間を削減し、新規事業を加速させる。
          </Box>
        </Box>
        <Box className={`text--txt title--head-news title--head-option2 transition--1s-slow`}>
          <Box className={`h2--value `}>News</Box>
          <Box className={`text--txt`}>
            定常的な情報収集や<span>ベンチマーク調査</span>
            にかかる時間を削減し、新規事業を加速させる。
          </Box>
        </Box>
        <Box
          className={`title--head-contact title--head-option2 title--head-option3 transition--1s-slow`}
        >
          <Box className={`h2--value h2--contact`}>Contact us</Box>
          <Box className={`text--txt`}>
            <span>InFolio</span>について詳しく知りたい方へ
          </Box>
        </Box>

        <Box className={`banner--top`}>
          <Box className={`image--bg`}>
            <img className={`img-fluid w-100`} src={bgBannerTop} alt='Information Portfolio' />
          </Box>
        </Box>
        <Box className={`banner--bottom transition-1s`}>
          <h1 className={`titleH1`}>
            Info<span>rmation</span> + <span>Port</span>folio = InFolio
          </h1>
          <div className={`description-txt`}>
            世界中の情報をリアルタイムで収集して、
            <br />
            あなたの代わりにAIがレポートを生成する。
          </div>
          <Box className={`banner--btn`}>
            <Link
              to='https://company.finchjapan.co.jp/contact.php'
              target='_bank'
              className={`btn--ctact scroll--contact`}
            >
              お問い合わせ
            </Link>
            <Link to='/' className={`btn--what`}>
              What is InFolio?
            </Link>
          </Box>
        </Box>
        <Box className={`reserved-i transition--1s-slow`}>
          ©Finchjapan, 2024. All rights reserved.
        </Box>
        <Box className={`block--scroll block-scroll--whatIs transition-1s`}>
          <Box className={`scroll--nt scroll--what`}>
            <img className={`img-fluid `} src={iconScrollBl} alt='What is InFolio?' />
            <span>What is InFolio?</span>
          </Box>
        </Box>
        {/* whatIs */}
        <Box className={`image--whatIs text-center  transition--1s-slow`}>
          <img className={`img-fluid`} src={imgWhatIsMac} alt='What is InFolio' />
        </Box>
        <Box className={`What--bottom-txt text-center transition-06s`}>
          <Box className={`text--txt`}>
            InFolioとは、情報検索と管理を一元化できるクラウドベースのプラットフォームです。クローリング技術を活用して最新情報をリアルタイムに収集し、独自のライブラリに保存して閲覧できます。インターネット接続さえあれば場所を選ばずアクセス可能で、検索から保存、
            <br />
            共有、出力までの一連の業務を自動化できます。
          </Box>
        </Box>
        <Box className={`block--scroll block-scroll--feature`}>
          <Box className={`scroll--nt scrollEC mb-50`}>
            <img className={`img-fluid imgRotate180`} src={iconScroll} alt='InFolio' />
            <span>InFolio</span>
          </Box>
          <Box className={`scroll--nt scroll--feature`}>
            <img className={`img-fluid`} src={iconScroll} alt='Features' />
            <span>Features</span>
          </Box>
        </Box>
        {/* feature */}
        <Box className={`slider--wrapper wow fadeInRight`} data-wow-delay='0.25s'>
          <Box className={`slider--feature none-scroll-bar scrollSync`} id='slider_Feature'>
            <Box className={`slider__Feature`}>
              <Box className={`item--li`}>
                <Box className={`item-feature`}>
                  <Box className={`top--item`}>
                    <Box className={`image--item`}>
                      <img className={`img-fluid `} src={feature1} alt='AI検索' />
                    </Box>
                    <Box className={`cate--top`}>
                      <Box className={`cat`}>
                        <img className={`img-fluid`} src={iconTxt1} alt='' />
                        <span>AI検索</span>
                      </Box>
                      <Box className={`txt-text`}>キーワードや自由記述から瞬時に検索</Box>
                    </Box>
                    <Box className={`bottom--item`}>
                      キーワードや自由記述から瞬時に検索・要約。様々なデータを、常に最新のオンラインリソースから素早く取得します。
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className={`item--li`}>
                <Box className={`item-feature`}>
                  <Box className={`top--item`}>
                    <Box className={`image--item`}>
                      <img className={`img-fluid `} src={feature2} alt='ベンチマーク' />
                    </Box>
                    <Box className={`cate--top`}>
                      <Box className={`cat`}>
                        <img className={`img-fluid `} src={iconTxt2} alt='' />
                        <span>ベンチマーク/競合調査</span>
                      </Box>
                      <Box className={`txt-text`}>
                        業界やベンチマーク/競合企業を絞った調査·自動要約が可能。
                      </Box>
                    </Box>
                    <Box className={`bottom--item`}>
                      業界やベンチマーク／競合企業を絞った調査・自動要約機能。繰り返しの作業を生みやすいベンチマーク／競合調査を半自動化します。
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className={`item--li`}>
                <Box className={`item-feature`}>
                  <Box className={`top--item`}>
                    <Box className={`image--item`}>
                      <img className={`img-fluid`} src={feature3} alt='リソース提示' />
                    </Box>
                    <Box className={`cate--top`}>
                      <Box className={`cat`}>
                        <img className={`img-fluid`} src={iconTxt3} alt='' />
                        <span>リソース提示</span>
                      </Box>
                      <Box className={`txt-text`}>
                        回答の根拠となるソースを表示し、信憑性が高いだけでなく、事実確認も容易。
                      </Box>
                    </Box>
                    <Box className={`bottom--item`}>
                      AI検索やベンチマーク調査で調べた内容については必ず回答の根拠となるリーソスを提示。 ハルシネーション*が起こる確率を下げるだけでなく、事実確認も容易。
                      <span className={`note-i`}>
                        *ハルシネーション：不正確だったり、誤っていたりする情報を、あたかも正しいように生成してしまうこと。
                      </span>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className={`item--li`}>
                <Box className={`item-feature`}>
                  <Box className={`top--item`}>
                    <Box className={`image--item`}>
                      <img className={`img-fluid`} src={feature4} alt='共有・出力機能' />
                    </Box>
                    <Box className={`cate--top`}>
                      <Box className={`cat`}>
                        <img className={`img-fluid`} src={iconTxt4} alt='' />
                        <span>共有・出力機能</span>
                      </Box>
                      <Box className={`txt-text`}>
                        入手した情報を社内で素早く共有し、共同編集も可能。
                      </Box>
                    </Box>
                    <Box className={`bottom--item`}>
                      調べた内容を、社内で素早く共有。共同編集も可能。社外向けにはPowerPointでそのまま出力ができます。
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={`block--scroll block-scroll--value transition-1s`}>
          <Box className={`scroll--nt scroll--what mb-50`}>
            <img className={`img-fluid imgRotate180`} src={iconScroll} alt='InFolioとは？' />
            <span>InFolioとは？</span>
          </Box>
          <Box className={`scroll--nt scroll--value-detail`}>
            <img className='img-fluid ' src={iconScroll} alt='Values' />
            <span>Values</span>
          </Box>
        </Box>
        <Box className={`value--detail transition--1s-slow`}>
          <Box className={`body--form`}>
            <Box className={`tab-wrapper`}>
              <Box component={'ul'} className={`tabs`}>
                <Box component={'li'} className={`tab-link active`} data-tab='1'>
                  Value 1
                </Box>
                <Box component={'li'} className={`tab-link`} data-tab='2'>
                  Value 2
                </Box>
              </Box>
            </Box>
            <Box className={`content-wrapper none-scroll-bar`}>
              <Box id='tab-1' className={`tab-1 tab-content active`}>
                <Box className={`body--content-wp`}>
                  <Box className={`image--ct-value`}>
                    <img className='img-fluid ' src={imgDetailVl1} alt='人的リソースをより創造的な活動へ' />
                  </Box>
                  <Box className={`content--ct-value`}>
                    <Box className={`head--top`}>
                      <h3 className={`title--dt-head`}>Value 1</h3>
                      <Box className={`des--txt-i`}>人的リソースをより創造的な活動へ</Box>
                    </Box>
                    <Box className={`txt--content-i`}>
                      InFolioは、繰り返し作業となりやすい情報収集やBM調査を半自動化し、これらにかかる時間を短縮することができます。こういった業務時間の削減により、ユーザーは事業開発や顧客との関係構築など、より付加価値の高いクリエイティブな業務に時間を割り当てることが可能になり、生産性の向上だけでなく、従業員の仕事への満足度の向上も期待できます。
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box id='tab-2' className={`tab-2 tab-content`}>
                <Box className={`body--content-wp`}>
                  <Box className={`image--ct-value`}>
                    <img className='img-fluid ' src={imgDetailVl2} alt='情報を自動で共有知化' />
                  </Box>
                  <Box className={`content--ct-value`}>
                    <Box className={`head--top`}>
                      <h3 className={`title--dt-head`}>Value 2</h3>
                      <Box className={`des--txt-i`}>情報を自動で共有知化</Box>
                    </Box>
                    <Box className={`txt--content-i`}>
                      InFolioの導入により、情報は見える化され、必要な時に必要な人々がアクセスできるようになります。
                      <br />
                      これにより、チームメンバーはそれぞれの専門知識を活かしながら、共有されたデータを基にして協力し、複雑な問題を効果的に解決することが可能です。また、InFolioのライブラリに貯められる知見は、御社の方向性を示す重要な情報となり、その後の新規事業立案業務の重要な礎にもなります。
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={`block--scroll block-scroll--news-detail transition-1s`}>
          <Box className={`scroll--nt scroll--feature mb-50`}>
            <img className={`img-fluid imgRotate180`} src={iconScroll} alt='Features' />
            <span>Features</span>
          </Box>
          <Box className={`scroll--nt scroll--news-detail`}>
            <img className='img-fluid ' src={iconScroll} alt='News' />
            <span>News</span>
          </Box>
        </Box>
        {/* news-detail */}
        <Box className={`news--detail transition--1s-slow`}>
          <Box className={`main--xBody`}>
            <Box className={`body--form`}>
              <Box className={`tab-wrapper `}>
                <Box component={'ul'} className={`tabs`}>
                  <Box component={'li'} className={`tab-link active transition-06s`} data-tab='1'>
                    ALL
                  </Box>
                  <Box component={'li'} className={`tab-link transition-06s`} data-tab='2'>
                    PRESS RELEASE
                  </Box>
                  <Box component={'li'} className={`tab-link transition-06s`} data-tab='3'>
                    MEDIA
                  </Box>
                  <Box component={'li'} className={`tab-link transition-06s`} data-tab='4'>
                    AWARD
                  </Box>
                </Box>
              </Box>
              <Box className={`content-wrapper none-scroll-bar`}>
                <Box id='tab-1' className={`tab-1 tab-content active`}>
                  <Box className={`block--body-news`} id=''>
                    <Box className={`item--news`}>
                      <Box className={`image-n`}>
                        <Link to='/'>
                          <img className='img-fluid ' src={news1} alt='生成AIを使った新サービス「InFolio」をローンチしました。' />
                        </Link>
                      </Box>
                      <Box className={`content-n`}>
                        <Box component={'ul'} className={`date--cat`}>
                          <Box component={'li'}>2024.06.07</Box>
                          <Box component={'li'}>
                            <Box className={`cat-i cat-PRESS`}>PRESS RELEASE</Box>
                          </Box>
                        </Box>
                        <Box className={`content--text`}>
                          <Box className={`txt-i`}>
                            生成AIを使った新サービス「InFolio」をローンチしました。調べたいことの最新リソースを取得し、生成AIを利用して要約した内容を返答してくれる「検索機能」、その検索結果を仲間に共有する「共有機能」を備えた新しい形の検索サービスです。現在プレオープンとしてお試し出来ますので是非ご利用ください。
                          </Box>
                          <Box className={`icon-i`}>
                            <Link to='/'>
                              <img className='img-fluid ' src={iconNtx} alt='' />
                            </Link>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box id='tab-2' className={`tab-2 tab-content`}>
                  <Box className={`block--body-news`}>
                    <Box className={`item--news`}>
                      <Box className={`image-n`}>
                        <Link to='/'>
                          <img className='img-fluid ' src={news1} alt='生成AIを使った新サービス「InFolio」をローンチしました。' />
                        </Link>
                      </Box>
                      <Box className={`content-n`}>
                        <Box component={'ul'} className={`date--cat`}>
                          <Box component={'li'}>2024.06.07</Box>
                          <Box component={'li'}>
                            <Box className={`cat-i cat-PRESS`}>PRESS RELEASE</Box>
                          </Box>
                        </Box>
                        <Box className={`content--text`}>
                          <Box className={`txt-i`}>
                            生成AIを使った新サービス「InFolio」をローンチしました。調べたいことの最新リソースを取得し、生成AIを利用して要約した内容を返答してくれる「検索機能」、その検索結果を仲間に共有する「共有機能」を備えた新しい形の検索サービスです。現在プレオープンとしてお試し出来ますので是非ご利用ください。
                          </Box>
                          <Box className={`icon-i`}>
                            <Link to='/'>
                              <img className='img-fluid ' src={iconNtx} alt='' />
                            </Link>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box id='tab-3' className={`tab-3 tab-content`}></Box>
                <Box id='tab-4' className={`tab-4 tab-content`}></Box>
              </Box>
            </Box>
          </Box>
          <Footer />
        </Box>
        <Box className={`block--scroll block-scroll--news-end transition-1s`}>
          <Box className={`scroll--nt scroll--value-detail`}>
            <img className={`img-fluid imgRotate180`} src={iconScroll} alt='Values' />
            <span>Values</span>
          </Box>
        </Box>
        {/* Contact */}
        <Box className={`contact--form transition--1s-slow`}>
          <ContactUs />
        </Box>
      </Box>
      <Box className={`scroll--nt scrollSP scrollEC `}>
        <img className={`img-fluid imgRotate180`} src={iconScroll} alt='InFolio' />
        <span>InFolio</span>
      </Box>
      <Box className={`scroll--nt scrollSP scroll--feature scroll--feature-t `}>
        <img className={`img-fluid`} src={iconScroll} alt='InFolioで出来ること' />
        <span>InFolioで出来ること</span>
      </Box>
      <Box className={`scroll--nt scrollSP scroll--what `}>
        <img className={`img-fluid imgRotate180`} src={iconScroll} alt='InFolioとは？' />
        <span>InFolioとは？</span>
      </Box>
      <Box className={`scroll--nt scrollSP scroll--value-detail scroll--value-b `}>
        <img className='img-fluid ' src={iconScroll} alt='Values' />
        <span>Values</span>
      </Box>
      <Box className={`scroll--nt scrollSP scroll--feature scroll--feature-v `}>
        <img className={`img-fluid imgRotate180`} src={iconScroll} alt='InFolioで出来ること' />
        <span>InFolioで出来ること</span>
      </Box>
      <Box className={`scroll--nt scrollSP scroll--news-detail scroll--news-b `}>
        <img className='img-fluid ' src={iconScroll} alt='News' />
        <span>News</span>
      </Box>
      <Box className={`scroll--nt scrollSP scroll--value-detail scroll--value-t  `}>
        <img className={`img-fluid imgRotate180`} src={iconScroll} alt='Values' />
        <span>Values</span>
      </Box>
    </Box>
  )
}

export default index
